import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import MerchantsApi from './MerchantsApi';
import MerchantsActions from './Components/MerchantsActions';
import { Status } from '@dex/bubl-dash';
import { WhiteSpace } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'merchants',
    zone: 'lists',
    path: '/merchants',
    title: "Merchants",
    endpoint: MerchantsApi.endpoint,
    modelName: 'Merchants',
    idKey: 'id',
    primaryKey: 'companyName',
    indexCaps: ["viewMerchants"],
    viewCaps: ["viewMerchants"],
    createCaps: ["createMerchants"],
    indexTitle: (routeName, params, donation) => {

        let title: string = settings.title;

        if (params && params.amount) title = title + " / " + titleCase(params.amount);

        return title;

    },
    indexTabs: (routeName, params, donation) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
            },
            {
                label: "Inactive",
                path: settings.path + "/status/inactive",
            },
            {
                label: "Limit Reached",
                path: settings.path + "/status/limitReached",
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
            {
                label: "Import Multiple",
                path: settings.path + "/import",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, donation) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: MerchantsApi.get,
    indexFetchParams: (routeName, params, donation) => {

        const filters: any = {
            where: {},
            include: ['product'],
            order: ['created.date DESC'],
            limit: 10,
            skip: 0
        };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        filters.include = [
            {
                relation: "product",
                scope: {
                    fields: ["id", "name", "modelName"]
                }
            },
            {
                relation: "vendor",
                scope: {
                    fields: ["id", "companyName", "modelName"]
                }
            }
        ];

        return filters;

    },
    indexExportFormats: (routeName, params, user) => {

        const formats = [
            { value: "merchants", label: "Merchants" }
        ];

        if (!user || !user.caps.includes("exportMerchants")) return [];

        return formats;

    },
    indexQuickSearch: (routeName, params, donation) => {

        const fields = ['companyName'];

        return fields;

    },
    indexSearchFields: (routeName, params, donation) => {

        const fields = [
            {
                label: "Merchant ID",
                key: "id",
                type: "text",
            },
            {
                label: "Company Name",
                key: "companyName",
                type: "text",
            },
            {
                label: "Trading Name",
                key: "tradingName",
                type: "text",
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, donation) => {

        const columns = [
            {
                label: "Company Name",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Status",
                key: "status",
                format: "status",
            },
            {
                label: "Trading Name",
                key: "tradingName",
            },
            {
                label: "MID",
                key: "mid",
                sort: true
            },
            {
                label: "Vendor",
                key: "vendor",
                format: "ModelLink",
            },
            {
                label: "Product",
                key: "product",
                format: "ModelLink",
            },
            {
                label: "Request Limit",
                key: "limitPerMonth",
                format: "number",
                suffix: " / month"
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                render: (value, data) => {

                    return <MerchantsActions data={data} />;

                }
            }
        ];

        return columns;

    },
    singleFetch: MerchantsApi.getOne,
    singleFetchParams: (routeName, params, donation, fetch) => {

        const filters = {
            include: ['product', 'vendor']
        };

        return filters;

    },
    singleTitle: (routeName, params, donation, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>{title}<WhiteSpace />{data && <Status status={data?.status} prefix='merchants.status' />}</>;

    },
    singleTabs: (routeName, params, donation, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};