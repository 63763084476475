import React, { useCallback, useMemo } from 'react';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Space } from '@dex/bubl-dash';
import { Line } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';
import { Alert } from '@dex/bubl-dash';

const StandingInstructionsImportReviewForm: React.FC<any> = (props: any) => {

    const { handleReset, instructions } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const getClassByStatus = useCallback((row) => {

        return row.rowStatus ? "table-bg-green" : "table-bg-red";

    }, []);

    const columns = [
        {
            label: "Message",
            key: "message",
        },
        {
            label: "MID",
            key: "mid",
        },
        {
            label: "Product",
            key: "product",
        },
        {
            label: "Batch",
            key: "batch",
        },
        {
            label: "Remarks",
            key: "remarks",
        },
    ];

    return (
        <>

            {instructions &&
                <>

                    {instructions.success > 0 &&
                        <>
                            <Alert
                                type={"success"}
                                description={instructions.success + " Requests Successfully Created."}
                            />
                            <Space />
                        </>
                    }

                    {instructions.error > 0 &&
                        <>
                            <Alert
                                type={"error"}
                                description={instructions.error + " Errors Occurred. Please check the list below."}
                            />
                            <Space />
                        </>
                    }

                    <DataTable
                        data={instructions.list}
                        columns={columns}
                        rowClassName={getClassByStatus}
                    />

                </>
            }

            <Line />

            <ButtonRow>

                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={handleReset}
                />

            </ButtonRow>

        </>
    );

}

export default StandingInstructionsImportReviewForm;
