import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import { Space } from '@dex/bubl-dash';
import StandingInstructionsSingleViewRequests from './StandingInstructionsSingleViewRequests';

const StandingInstructionsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Instruction Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Merchant",
                                key: "merchant",
                                format: "ModelLink",
                            },
                            {
                                label: "Product",
                                key: "product",
                                format: "ModelLink",
                            },
                            {
                                label: "Remarks",
                                key: "remarks",
                                format: "nl2br",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Batch",
                                key: "batch",
                                format: "titleCase",
                            },
                            {
                                label: "Next Instruction Date",
                                key: "nextDate",
                                format: "date",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase",
                            },
                            {
                                label: "Created Date",
                                key: "created",
                                format: "onAndBy",
                            }
                        ]}
                    />

                </Panel>

                <Space />

                <StandingInstructionsSingleViewRequests data={data} />


            </Col>

        </Row>

    ), [data]);

}

export default StandingInstructionsSingleView;
