import React, { useCallback } from 'react';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import { Space } from '@dex/bubl-dash';
import { DataTable } from '@dex/bubl-dash';
import { FieldHidden } from '@dex/bubl-dash';
import { Alert } from '@dex/bubl-dash';

const StandingInstructionsImportValidateForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, instructions, handleReset } = props;

    const columns = [
        {
            label: "Message",
            key: "message",
        },
        {
            label: "MID",
            key: "mid",
        },
        {
            label: "Number Of Rolls",
            key: "numberOfRolls",
        },
        {
            label: "Batch",
            key: "batch",
            format: "titleCase"
        },
        {
            label: "Status",
            key: "status",
            format: "titleCase"
        },
        {
            label: "Remarks",
            key: "remarks",
        },
    ];

    // eslint-disable-next-line
    let data = props.data || {};

    const getClassByStatus = useCallback((row) => {

        return row.valid ? "" : "table-bg-yellow";

    }, []);

    return (

        <Form
            form={form}
            onSubmit={handleSubmit}
            loading={process.loading}
        >

            <FieldHidden
                form={form}
                name={'instructions'}
                defaultValue={form.values.instructions}
            />

            {instructions.success > 0 &&
                <>
                    <Alert
                        type={"info"}
                        message={instructions.success + " instructions Found."}
                        description={"Click submit below to generate instruction requests."}
                    />
                    <Space />
                </>
            }

            {instructions.error > 0 &&
                <>
                    <Alert
                        type={"warning"}
                        description={instructions.error + " Errors Occurred. Please check the list below."}
                    />
                    <Space />
                </>
            }

            <DataTable
                data={instructions.list}
                columns={columns}
                rowClassName={getClassByStatus}
            />

            <Space />

            <ButtonRow>

                <Button
                    label={"Import instructions"}
                    loading={process.loading}
                />

                <Button
                    label={"Reset"}
                    type={"faded"}
                    onClick={handleReset}
                />

            </ButtonRow>

        </Form>

    );
}

export default StandingInstructionsImportValidateForm;
